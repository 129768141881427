import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { AuthRecoverResetFlow } from '@luigiclaudio/ga-auth-theme';
import MainContainer from '../components/pageLayout/MainContainer';
import SiteFooter from '../components/pageLayout/SiteFooter';

const AuthRecoverResetFlowPage = ({ location }) => {
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <SiteContentWrapper>
                    <Container isFullHeight>
                        <Row isFullHeight justifyContent="center">
                            <Col xxs={30}>
                                <MainContainer>
                                    {hasMounted && (
                                        <AuthRecoverResetFlow
                                            location={location}
                                            buttonSkin="muted"
                                            loaderBackgroundColor={theme.site.stage.backgroundColor}
                                        />
                                    )}
                                </MainContainer>
                            </Col>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            }
            footer={<SiteFooter />}
        />
    );
};

AuthRecoverResetFlowPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            token: PropTypes.string,
        }),
    }).isRequired,
};

export default AuthRecoverResetFlowPage;
